import { useMsal, useAccount } from '@azure/msal-react';
import * as Sentry from '@sentry/react';
import { setUser as setSentryUser } from '@sentry/react';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import { useAxios } from './common/hooks/useAxios';
import { config } from './config';

if (config.SENTRY_DSN) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({ maskAllText: false, maskAllInputs: false }),
    ],
    release: config.SENTRY_RELEASE,
    environment: config.SENTRY_ENVIRONMENT,
    tracesSampleRate: config.SENTRY_TRACESSAMPLERATE,
    ignoreErrors: ['ChunkLoadError', /^Loading chunk$/],
    replaysSessionSampleRate: config.SENTRY_REPLAYSSESSIONSAMPLERATE,
    replaysOnErrorSampleRate: config.SENTRY_REPLAYSONERRORSAMPLERATE,
  });
}

export const SentryInstance = (): null => {
  const account = useAccount();
  const { instance } = useMsal();

  useAxios(account, instance);

  const userEmail: string = (account?.idTokenClaims?.email ?? account?.idTokenClaims?.emails?.[0] ?? account?.username) as string;

  useEffect(() => {
    setSentryUser({ email: userEmail });
  }, [userEmail]);

  return null;
};
