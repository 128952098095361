import { useAccount, useMsal } from '@azure/msal-react';
import { useCallback } from 'react';
import { apiRequest, loginRequest } from '../../authConfig';
import { captureMessage } from '@sentry/react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

export const useAccessToken = () => {
  const { instance } = useMsal();
  const account = useAccount();

  const getAccessToken = useCallback(async () => {
    const accessTokenRequest = {
      ...apiRequest,
      account: account!,
    };

    try {
      const token = await instance.acquireTokenSilent({
        ...accessTokenRequest,
        forceRefresh: false,
      });
      if (!token) throw new InteractionRequiredAuthError();
      return token;
    } catch (acquireSilentError) {
      if (acquireSilentError instanceof InteractionRequiredAuthError) {
        try {
          await instance.acquireTokenRedirect({
            ...accessTokenRequest,
            prompt: 'login',
          });
        } catch (aquireRedirectError) {
          captureMessage('useAccessToken failed to acquire token silently', {
            extra: { error: aquireRedirectError },
          });
          try {
            await instance.loginRedirect({ ...loginRequest, account: account! });
          } catch (loginRedirectError) {
            console.error(loginRedirectError);
            captureMessage('useAccessToken failed to acquire token silently', {
              extra: { error: loginRedirectError },
            });
          }
        }
      }
      return Promise.resolve();
    }
  }, [account, instance]);

  return getAccessToken;
};
