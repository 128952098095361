import { useAccount, useMsal } from '@azure/msal-react';
import { useEffect } from 'react';

export const LogoutPage = (): null => {
  const { instance } = useMsal();
  const account = useAccount();

  useEffect(() => {
    instance.logoutRedirect({
      account,
    });
  }, [instance, account]);

  return null;
};
