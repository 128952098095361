export const tintColorLight = '#2f95dc';
export const tintColorDark = '#fff';
export const white = '#FFFFFF';
export const offWhite = '#E7E7E7';
export const black = '#000000';
export const black60 = 'rgba(0, 0, 0, 0.6)';
export const black38 = 'rgba(0, 0, 0, 0.38)';
export const black23 = 'rgba(0, 0, 0, 0.23)';
export const black12 = 'rgba(0, 0, 0, 0.12)';
export const black04 = 'rgba(0, 0, 0, 0.04)';
export const defaultBackgroundColor = '#F4F4F4';
export const defaultDisabledBackgroundColor = '#878787';
export const defaultTextColor = 'rgba(0,0,0,0.87)';
export const secondaryTextColor = 'rgba(0,0,0,0.6)';
export const defaultBorderColor = '#DADADA';
export const defaultPrimaryColor = '#DA1B31';
export const defaultWarnColor = '#F58535';
export const darkButtonColor = '#404040';
export const greyIconColor = '#bdbdbd';
export const sectionTitleColor = 'rgba(0,0,0,0.54)';
export const appBarColor = '#121212';
export const defaultDarkColor = '#313131';
export const green = '#4CAF50';
export const seaGreen = '#2e7d32';
export const greenPeas = '#8BC34A';
export const yellow = '#F8D849';
export const orange = '#E68A48';
export const red = '#BF2424';
export const lightGray = '#D0D0D0';
export const gray = '#b3b3b3';
export const darkGray = '#6a6b6b';
export const charcoal = '#313131EE';
export const charcoalGray = '#4D4D4F';
export const grayWithLightRedShade = '#E0E0E0';
export const tableBorderColor = '#D7D7D7';
export const darkGreen = '#407A3B';
export const darkOrange = '#E65100';
export const blue = '#2579B3';
export const darkenWhite = '#E0E0E0';
export const deepPurple = '#673AB7';
export const backgroundErrorColor = '#D32F2F1F';
export const disableCellBackgroundColor = '#00000014';

export const pink = '#EA47BC';
export const tableHeaderColor = 'rgba(49, 49, 49, 0.04)';
export const reservedOrdreDeTravailBackground = 'repeating-linear-gradient(-45deg, #FFFFFF, #FFFFFF 10px, #F5F5F5 10px, #F5F5F5 20px)';

export const darkReservedOrdreDeTravailBackground = 'repeating-linear-gradient(-45deg, #E0E0E0, #E0E0E0 10px, #EEEEEE 10px, #EEEEEE 20px)';

export const colors = {
  dark: {
    background: '#252842',
    tabIconDefault: '#ccc',
    text: white,
    tint: tintColorDark,
  },
  light: {
    background: white,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
    text: {
      primary: defaultTextColor,
      secondary: secondaryTextColor,
    },
    tint: tintColorLight,
  },
  status: {
    red: '#EF534F',
    gray: '#BDBDBD',
    orange: '#FF7143',
    green: '#007A4B',
  },
  chip: {
    green: darkGreen,
    blue,
    yellow: '#FFD600',
    red: '#CF3637',
    dark: defaultDarkColor,
    white: darkenWhite,
    deepPurple: deepPurple,
    greenPeas: greenPeas,
    charcoal: charcoal,
  },
};
