import { ApolloProvider } from '@apollo/client';
import { useMsal, useAccount } from '@azure/msal-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { PropsWithChildren } from 'react';
import { useAxios } from './common/hooks/useAxios';
import useSetupApollo from './common/hooks/useSetupApollo';
import { PageLoader } from './layout/components/PageLoader';

export const appQueryClient = new QueryClient({});

export const RequestClients = ({ children }: PropsWithChildren): JSX.Element => {
  const account = useAccount();
  const { instance } = useMsal();

  useAxios(account, instance);
  const [apolloClient, isApolloReady] = useSetupApollo(account, instance);

  if (!isApolloReady) return <PageLoader />;

  return (
    <ApolloProvider client={apolloClient}>
      <QueryClientProvider client={appQueryClient}>{children}</QueryClientProvider>
    </ApolloProvider>
  );
};
