/* eslint-disable @typescript-eslint/no-explicit-any */
import { BehaviorSubject, fromEventPattern, Observable } from 'rxjs';
import { HttpTransportType, HubConnection, HubConnectionBuilder, IHttpConnectionOptions } from '@microsoft/signalr';
import { catchError, share, shareReplay, switchMap, take } from 'rxjs/operators';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { deref, of, swap } from '@known-as-bmf/store';
import { toast } from 'react-toastify';
import { useAccount, useMsal } from '@azure/msal-react';
import { apiRequest } from '../../authConfig';
import { config } from '../../config';

const connectionCacheStore = of({});

const addToCache = (hubUrl: string, entry: any) =>
  swap(connectionCacheStore, (state: any) => {
    state[hubUrl] = entry;
    return state;
  });

const getFromCache = (hubUrl: string) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { [hubUrl]: entry } = deref(connectionCacheStore);
  return entry;
};

const removeFromCache = (hubUrl: string) =>
  swap(connectionCacheStore, (state: any) => {
    delete state[hubUrl];
    return state;
  });

const handleCatchError = (error: any, caught: Observable<unknown>) => {
  toast.error('error');
  return caught;
};

const createConnection = (url: string, options = {}) =>
  new HubConnectionBuilder()
    .withUrl(url, {
      ...options,
      transport: config.HUB_FORCE_LONG_POLLING ? HttpTransportType.LongPolling : undefined,
    })
    .withAutomaticReconnect()
    .withStatefulReconnect()
    .build();

const setupConnection = (hubUrl: string, options: IHttpConnectionOptions) =>
  new Observable((observer) => {
    const connection = createConnection(hubUrl, options);

    connection.onclose(() => {
      removeFromCache(hubUrl);
      observer.complete();
    });

    connection.onreconnected(() => {
      observer.next(connection);
    });

    connection
      .start()
      .then(() => observer.next(connection))
      .catch(() => {
        removeFromCache(hubUrl);
        toast.error('error');
      });

    return () => connection.stop();
  }).pipe(
    // everyone subscribing will get the same connection
    // refCount is used to complete the observable when there is no subscribers left
    shareReplay({ refCount: true, bufferSize: 1 }),
    catchError(handleCatchError),
  );

const getOrSetupConnection = (hubUrl: string, options: IHttpConnectionOptions) => {
  let connection = getFromCache(hubUrl);

  if (!connection) {
    connection = setupConnection(hubUrl, options);
    addToCache(hubUrl, connection);
  }

  return connection;
};

const useSignalr = (
  hubUrl: string,
  connectionOptions = {},
): {
  invoke: (methodName: string, arg: any) => any;
  send: (methodName: string, arg: any) => any;
  on: (methodName: string) => any;
  connectionId: string | undefined;
} => {
  const { instance } = useMsal();
  const account = useAccount();

  const [connectionId, setConnectionId] = useState('');

  const getToken = useCallback(async () => {
    const response = await instance.acquireTokenSilent({
      ...apiRequest,
      account: account!,
    });

    return response.accessToken;
  }, [account, instance]);

  const connection = useMemo(
    () =>
      getOrSetupConnection(hubUrl, {
        accessTokenFactory: getToken,
        ...connectionOptions,
      }),
    [getToken, hubUrl, connectionOptions],
  );

  useEffect(() => {
    // used to maintain 1 active subscription while the hook is rendered
    const subscription = connection.subscribe();
    return () => subscription.unsubscribe();
  }, [connection]);

  const send = useCallback(
    (methodName: string, arg: any) => {
      if (process.env.NODE_ENV === 'development') {
        console.info(methodName, arg);
      }
      return connection
        .pipe(
          take(1),
          switchMap((c: any) => c.send(methodName, arg)),
          catchError(handleCatchError),
        )
        .toPromise();
    },
    [connection],
  );

  const invoke = useCallback(
    (methodName: string, arg: any) =>
      connection
        .pipe(
          take(1),
          switchMap((c: any) => c.invoke(methodName, arg)),
          catchError(handleCatchError),
        )
        .toPromise(),
    [connection],
  );

  const on = useCallback(
    (methodName: string) =>
      connection
        .pipe(
          take(1),
          switchMap((c: any) =>
            fromEventPattern(
              (handler) => c.on(methodName, handler),
              (handler) => c.off(methodName, handler),
            ),
          ),
          catchError(handleCatchError),
        )
        .pipe(share()),
    [connection],
  );

  useEffect(() => {
    const subject = new BehaviorSubject<HubConnection>({
      connectionId: '',
    } as HubConnection);
    connection.subscribe(subject);
    subject.subscribe((next) => setConnectionId(next.connectionId || ''));
  }, [connection]);

  return { invoke, on, send, connectionId };
};

export default useSignalr;
