import { FlagProvider } from '@unleash/proxy-client-react';
import { PropsWithChildren } from 'react';
import { IConfig } from 'unleash-proxy-client';
import { config as env } from './config';
import { useAccount } from '@azure/msal-react';

const featureFlagsConfig: IConfig = {
  url: env.FEATURE_FLAG_HOST,
  clientKey: env.FEATURE_FLAG_CLIENT_KEY,
  refreshInterval: env.FEATURE_FLAG_REFRESH_INTERVAL_SECONDS,
  appName: env.SENTRY_ENVIRONMENT,
  environment: env.SENTRY_ENVIRONMENT,
};

export const Flags = ({ children }: PropsWithChildren): JSX.Element => {
  const account = useAccount();

  const userEmail: string = (account?.idTokenClaims?.email ?? account?.idTokenClaims?.emails?.[0] ?? account?.username) as string;

  return <FlagProvider config={{ ...featureFlagsConfig, context: { userId: userEmail } }}>{children}</FlagProvider>;
};
