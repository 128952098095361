import { Configuration, LogLevel } from '@azure/msal-browser';
import { config } from './config';

export const msalConfig = {
  auth: {
    authority: config.AUTH_AUTHORITY || '',
    clientId: config.AUTH_CLIENT_ID || '',
    redirectUri: config.AUTH_REDIRECT_URI || '',
    postLogoutRedirectUri: config.AUTH_LOGIN_REDIRECT || '',
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    allowRedirectInIframe: true,
    loggerOptions: {
      loggerCallback: (level: never, message: never, containsPii: never) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            // eslint-disable-next-line no-restricted-syntax
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
} as Configuration;

export const loginRequest = {
  scopes: ['User.Read'],
  state: config.AUTH_LOGIN_REDIRECT,
};

export const apiRequest = {
  redirectUri: config.AUTH_REDIRECT_URI || '',
  scopes: ['openid', 'profile', 'email', 'offline_access', config.AUTH_API_SCOPE || ''],
};
