import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { getRoutes } from './routes';
import { useMemo } from 'react';
import { useFlag } from '@unleash/proxy-client-react';
import { wrapCreateBrowserRouter } from '@sentry/react';

export const Router = (): JSX.Element => {
  const show_dispatch_routes = useFlag('show_dispatch_routes');
  const mock_data_generation_enabled = useFlag('mock_data_generation');
  const show_configuration_routes = useFlag('show_configuration_routes');

  const sentryBrowserRouter = useMemo(
    () =>
      wrapCreateBrowserRouter(createBrowserRouter)(
        getRoutes(show_dispatch_routes, show_configuration_routes, mock_data_generation_enabled),
      ),
    [show_dispatch_routes, show_configuration_routes, mock_data_generation_enabled],
  );

  return <RouterProvider router={sentryBrowserRouter} />;
};
