import { StrictMode, useEffect } from 'react';
import { App } from './App';
import { LicenseInfo } from '@mui/x-license';
import { Authenticator } from './Authenticator';
import { SentryInstance } from './Sentry';

import './i18n';
import './index.css';

LicenseInfo.setLicenseKey('c30e8717856dafde3eee8b4cffefb87fTz05Mjc1NSxFPTE3NTAzNjEwNzgwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

export const Root = () => {
  useEffect(() => {
    const handleVitePreloadError = () => window.location.reload();
    window.addEventListener('vite:preloadError', handleVitePreloadError);

    return () => {
      window.removeEventListener('vite:preloadError', handleVitePreloadError);
    };
  }, []);

  return (
    <StrictMode>
      <Authenticator>
        <SentryInstance />
        <App />
      </Authenticator>
    </StrictMode>
  );
};
